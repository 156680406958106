import Axios from '@/axios';
import config from '@/config';

export default {
  createOrder: async (body) => {
    const response = await Axios.post(`${config.PAYMENT_API_URL}/payment-service/v2/payments`, body);

    return response.data.data;
  }
};
