<template>
  <div>
    <div class="flex">
      <BaseButton
        v-b-modal.create-order-modal
        variant="secondary-outline"
        :size="compact ? 'sm' : 'md'"
        :disabled="disabled"
        v-if="allowOrderCreation"
      >
        <span>Create Order</span>
      </BaseButton>
      <span
        v-if="disabled"
        id="button-disabled"
        class="ml-2 align-top"
        v-b-tooltip.hover.top="'Orders cannot be created on closed or draft Raffles.'"
      >
        <i class="fa-solid fa-circle-info"></i>
      </span>
    </div>
    <b-modal
      id="create-order-modal"
      title="Create Order"
      v-model="modalShow"
      @show="resetForm"
      @hidden="resetForm"
      @ok="handleOk"
      no-close-on-backdrop
      size="xl"
      body-class="position-static"
    >
      <b-overlay :show="loading" no-wrap rounded="sm" />

      <b-form class="create-order" @submit.stop.prevent="onSubmit">
        <b-form-row>
          <b-col md="12 mb-4">
            <Alert v-if="errorMessage" variant="red" icon="exclamation">{{ errorMessage }}</Alert>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col md="6" class="mb-4">
            <b-card header="Raffle Details">
              <b-form-group label-for="select-event" :invalid-feedback="veeErrors.first('select-event')" class="mb-5">
                <template v-slot:label>Raffle <span class="text-danger">*</span> </template>
                <b-form-select
                  name="select-event"
                  v-model="selectedEventId"
                  :options="optionsEvents"
                  v-validate="{ required: true }"
                  :state="validateState('select-event')"
                  data-vv-as="raffle"
                ></b-form-select>
              </b-form-group>

              <div class="ticket-container">
                <b-form-group label-for="select-tickets" :invalid-feedback="veeErrors.first('select-tickets')">
                  <template v-slot:label>Ticket Packages </template>
                  <b-form-select
                    name="select-tickets"
                    v-model="ticketPackage"
                    :options="optionsTicketPackages"
                    :state="ticketPackageValid"
                    data-vv-as="ticket packages"
                    @change="validateTicketPackagesOnChange()"
                  ></b-form-select>
                  <div tabindex="-1" role="alert" aria-live="assertive" aria-atomic="true" class="invalid-feedback">
                    The ticket packages field is required
                  </div>
                </b-form-group>

                <b-form-group
                  label-for="ticket-quantity-prize"
                  :invalid-feedback="veeErrors.first('ticket-quantity-prize')"
                  :class="{ 'h-[8.125rem]': !this.quantityValid }"
                >
                  <template v-slot:label>Quantity </template>
                  <b-form-input
                    id="ticket-quantity-prize"
                    type="number"
                    v-model="quantity"
                    :state="quantityValid"
                    @change="validateTicketPackagesOnChange()"
                    @input="quantityValidation('quantity')"
                    min="0"
                  ></b-form-input>
                  <div tabindex="-1" role="alert" aria-live="assertive" aria-atomic="true" class="invalid-feedback">
                    {{ quantityErrorMessage }}
                  </div>
                </b-form-group>
              </div>

              <div v-if="selectedEvent && selectedEvent.childEvents.length" class="mt-5">
                <h5>Bonus Draw Raffle</h5>
                <div class="ticket-container">
                  <b-form-group
                    label-for="select-tickets-addon"
                    :invalid-feedback="veeErrors.first('select-tickets-addon')"
                  >
                    <template v-slot:label>Ticket Packages </template>
                    <b-form-select
                      name="select-tickets-addon"
                      v-model="ticketPackageAddon"
                      :options="optionsAddonTicketPackages"
                      :state="ticketPackageAddonValid"
                      data-vv-as="ticket packages"
                      @change="validateTicketPackagesOnChange()"
                    ></b-form-select>
                    <div tabindex="-1" role="alert" aria-live="assertive" aria-atomic="true" class="invalid-feedback">
                      The ticket packages field is required
                    </div>
                  </b-form-group>

                  <b-form-group
                    label-for="ticket-quantity-addon"
                    :invalid-feedback="veeErrors.first('ticket-quantity-addon')"
                    :class="{ 'h-[8.125rem]': !quantityAddonValid }"
                  >
                    <template v-slot:label>Quantity </template>
                    <b-form-input
                      id="ticket-quantity-addon"
                      type="number"
                      v-model="quantityAddon"
                      :state="quantityAddonValid"
                      @change="validateTicketPackagesOnChange()"
                      @input="quantityValidation('quantityAddon')"
                    ></b-form-input>
                    <div tabindex="-1" role="alert" aria-live="assertive" aria-atomic="true" class="invalid-feedback">
                      {{ quantityErrorMessage }}
                    </div>
                  </b-form-group>
                </div>
              </div>

              <div v-if="selectedEvent && selectedEvent.supportStatsActive" class="mt-5">
                <b-form-group
                  label-for="input-selected-event-member"
                  :invalid-feedback="veeErrors.first('input-selected-event-member')"
                  :state="validateState('input-selected-event-member')"
                >
                  <template v-slot:label>In Support Of <span class="text-danger">*</span> </template>
                  <EventMemberSelectV2
                    name="input-selected-event-member"
                    :eventId="selectedEvent.id"
                    v-model="selectedEventMember"
                    v-validate="{ required: eventMemberRequired }"
                    :state="validateState('input-selected-event-member')"
                    :hideEntireGroup="hideEntireGroup"
                    data-vv-as="in support of"
                  />
                </b-form-group>
              </div>
            </b-card>
          </b-col>
          <b-col md="6" class="mb-4">
            <b-card header="Order Details">
              <CustomerIdSearch
                ref="customerSearch"
                v-if="selectedEvent && selectedEventId"
                v-model="selectedCustomer"
                class="mb-5"
              />

              <div class="order-container">
                <b-form-group label-for="order-type" :invalid-feedback="veeErrors.first('order-type')" class="mb-5">
                  <template v-slot:label>Order Type <span class="text-danger">*</span> </template>
                  <b-form-select
                    name="order-type"
                    v-model="selectedOrderType"
                    :options="optionsOrderType"
                    v-validate="{ required: true }"
                    :state="validateState('order-type')"
                    data-vv-as="order type"
                  ></b-form-select>
                </b-form-group>

                <b-form-group label-for="order-source" :invalid-feedback="veeErrors.first('order-source')">
                  <template v-slot:label>Order Source</template>
                  <b-form-select
                    name="order-source"
                    v-model="selectedOrderSource"
                    :options="optionsOrderSource"
                    :state="validateState('order-source')"
                    data-vv-as="order source"
                  ></b-form-select>
                </b-form-group>
              </div>
            </b-card>
          </b-col>
        </b-form-row>

        <b-form-row v-if="canViewDonations && donationEnabled">
          <b-col md="12" class="mb-4">
            <b-card header="Donation Details">
              <b-form-group label-for="donation" :invalid-feedback="veeErrors.first('donation')">
                <template v-slot:label>Donation Amount</template>
                <b-row class="mb-4">
                  <b-col md="6">
                    <b-form-input
                      id="donation"
                      v-model="donation"
                      v-validate="{ numeric: true, min_value: 0 }"
                      :state="validateState('donation')"
                      name="donation"
                      @input="donationValidation"
                    />
                    <div tabindex="-1" role="alert" aria-live="assertive" aria-atomic="true" class="invalid-feedback">
                      {{ donationErrorMessage }}
                    </div>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-card>
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col md="12" class="mb-4">
            <b-card header="Customer Information">
              <b-row class="mb-4">
                <b-col md="6">
                  <TextInput
                    ref="firstName"
                    label="First Name"
                    name="first-name"
                    v-model="firstName"
                    :required="true"
                  />
                </b-col>
                <b-col md="6">
                  <TextInput ref="lastName" label="Last Name" name="last-name" v-model="lastName" :required="true" />
                </b-col>
              </b-row>
              <b-row class="mb-4">
                <b-col>
                  <TextInput
                    ref="secondaryName"
                    label="Additional Name"
                    name="secondary-name"
                    v-model="secondaryName"
                    :required="false"
                    :description="'Use this field to add additional names, recipients or groups to your purchase'"
                    v-if="allowSecondaryName"
                  />
                </b-col>
              </b-row>
              <b-row class="mb-6">
                <b-col md="6">
                  <TextInput ref="address" label="Address" name="address" min="3" v-model="address" />
                </b-col>
                <b-col md="6">
                  <TextInput ref="city" label="City" name="city" v-model="city" />
                </b-col>
              </b-row>
              <b-row class="mb-5">
                <b-col md="6">
                  <b-form-group label-for="province" :invalid-feedback="veeErrors.first('province')">
                    <template v-slot:label
                      >{{ provinceStateView.dropdownLabel }}
                      <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      name="province"
                      v-model="province"
                      :options="provinceStateOptions"
                      v-validate="{ required: true }"
                      :state="validateState('province')"
                      data-vv-as="province"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <PostalInput
                    v-if="this.country === 'CA'"
                    ref="postalCode"
                    label="Postal Code"
                    name="postal-code"
                    v-model="postalCode"
                    :province="province"
                  />
                  <ZipInput
                    v-if="this.country === 'US'"
                    ref="postalCode"
                    label="ZIP Code"
                    name="postal-code-billing"
                    v-model="postalCode"
                    :province="province"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <EmailInput ref="email" label="Email" name="email" v-model="email" />
                </b-col>
                <b-col md="6">
                  <b-form-group :invalid-feedback="veeErrors.first('input-phone')">
                    <template v-slot:label> Phone <span class="text-danger">*</span> </template>
                    <b-form-input
                      name="input-phone"
                      v-model="phone"
                      v-validate="{ required: true, min: 10 }"
                      :state="validateState('input-phone')"
                      data-vv-as="phone"
                      trim
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="mb-3">
                <b-col>
                  <b-form-group
                    label="Comment"
                    label-for="input-comments"
                    :invalid-feedback="veeErrors.first('input-comments')"
                  >
                    <b-form-textarea
                      id="textarea"
                      v-model="comment"
                      placeholder="Enter comment..."
                      rows="3"
                    ></b-form-textarea>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-form-row>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { formatCurrency } from '@rafflebox-technologies-inc/rafflebox-lib';
import EventMemberSelectV2 from '@/components/EventMemberSelectV2.vue';
import TextInput from '@/components/inputs/TextInput';
import EmailInput from '@/components/inputs/EmailInput';
import PostalInput from '@/components/inputs/PostalInput';
import ZipInput from '@/components/inputs/ZipInput';
import CustomerIdSearch from '@/components/inputs/CustomerIdSearch';
import EventServiceV2 from '@/lib/event-service-v2';
import OrganizationServiceV2 from '@/lib/organization-service-v2';
import PaymentService from '@/lib/payment-service';
import ProvinceStateService from '@/lib/province-state-view-service';
import { isAfter, parseISO } from 'date-fns';
import { unleashFeatureEnabled, UnleashKeys } from '@/lib/unleash';
import Alert from '@/components/ui/Alert.vue';
import BaseButton from '@/components/rbComponents/BaseButton.vue';

const orderTypes = [
  { value: null, text: '--- Select a type ---' },
  { value: 'cash', text: 'Cash' },
  { value: 'cheque', text: 'Cheque' },
  { value: 'money order', text: 'Money Order' }
];

const orderSource = [
  { value: null, text: '--- Select a source ---' },
  { value: 'mail', text: 'Mail' },
  { value: 'phone', text: 'Phone' },
  { value: 'walk_in', text: 'Walk in' }
];

export default {
  props: {
    event: {
      type: Object
    },
    disabled: {
      type: Boolean
    },
    compact: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Alert,
    EventMemberSelectV2,
    TextInput,
    EmailInput,
    PostalInput,
    CustomerIdSearch,
    ZipInput,
    BaseButton
  },
  data() {
    return {
      modalShow: false,
      errorMessage: null,
      firstName: null,
      lastName: null,
      secondaryName: null,
      email: null,
      phone: null,
      address: null,
      city: null,
      province: null,
      provinceStateOptions: {},
      postalCode: null,
      comment: null,
      selectedCustomer: null,
      quantity: null,
      quantityAddon: null,
      optionsEvents: null,
      optionsTicketPackages: null,
      optionsAddonTicketPackages: null,
      ticketPackage: null,
      ticketPackageAddon: null,
      provinceStateView: {},
      country: null,
      selectedEventMember: null,
      ticketPackagesLoading: false,
      selectedEvent: this.event,
      selectedEventId: null,
      submitting: false,
      selectedOrderType: null,
      optionsOrderType: orderTypes,
      selectedOrderSource: null,
      optionsOrderSource: orderSource,
      ticketPackageValid: null,
      ticketPackageAddonValid: null,
      quantityValid: null,
      quantityAddonValid: null,
      quantityErrorMessage: null,
      allowSecondaryName: false,
      donation: null,
      canViewDonations: false,
      donationEnabled: false,
      donationErrorMessage: null
    };
  },

  async created() {
    const organization = await OrganizationServiceV2.retrieveOrganization(this.$store.state.user.organizationUuid);
    this.country = organization.country;
    this.provinceStateView = ProvinceStateService.getDefaultView(this.country);
    this.provinceStateOptions = this.provinceStateView.dropdownOptions;
  },
  computed: {
    loading() {
      return this.ticketPackagesLoading || this.submitting;
    },
    eventMemberRequired() {
      return this.selectedEvent?.supportStatsForceSelectionEnabled;
    },
    hideEntireGroup() {
      return this.selectedEvent?.settings?.hideEntireGroup;
    },
    allowOrderCreation() {
      if (this.selectedEvent) {
        return isAfter(new Date(), parseISO(this.selectedEvent?.startDate));
      }

      return true;
    }
  },
  watch: {
    selectedEventId: async function () {
      this.resetForm();

      this.optionsTicketPackages = null;

      if (!this.selectedEventId) {
        this.selectedEvent = null;
        return;
      }

      this.ticketPackagesLoading = true;

      try {
        const event = await EventServiceV2.retrieveEvent(this.selectedEventId);

        this.selectedEvent = event;

        if (this.selectedEvent.childEvents.length) {
          this.addonTicketPackages(this.selectedEvent.childEvents[0].id);
        }

        this.province = this.selectedEvent.province;

        const packages = [];

        packages.push({
          value: null,
          text: 'Select one',
          disabled: false
        });

        for (const ticketPackage of event.ticketPackages) {
          if (ticketPackage.availableForPurchaseV2 !== false) {
            const sold = ticketPackage.availableForPurchaseV2 === 'soldout' ? '[Sold Out]' : '';

            packages.push({
              value: ticketPackage,
              text: `${ticketPackage.numTickets} for ${this.formatCurrency(ticketPackage.price)} ${sold}`,
              disabled: sold ? true : false
            });
          }
        }

        this.allowSecondaryName = this.selectedEvent.settings.allowSecondaryName;
        this.donationEnabled = this.selectedEvent.settings.donationEnabled;
        this.optionsTicketPackages = packages;
      } catch (error) {
        this.errorMessage = this.parseError(error).message;
      }
      this.ticketPackagesLoading = false;
    },
    selectedCustomer: async function () {
      // Populate fields with selected Customer information.
      if (this.selectedCustomer?.value) {
        this.firstName = this.selectedCustomer.value?.firstName ?? null;
        this.lastName = this.selectedCustomer.value?.lastName ?? null;
        this.email = this.selectedCustomer.value?.email ?? null;
        this.phone = this.selectedCustomer.value?.phone ?? null;
        this.address = this.selectedCustomer.value?.address ?? null;
        this.city = this.selectedCustomer.value?.city ?? null;
        this.province = this.provinceSelection(this.selectedCustomer.value?.province ?? null);
        this.postalCode = this.selectedCustomer.value?.postal ?? null;
      }
    }
  },
  async mounted() {
    const params = {
      status: 'not_ended_and_active',
      pageSize: 50
    };

    try {
      const optionsEvents = [];

      optionsEvents.push({ value: null, text: '--- Select a Raffle ---' });

      const response = await EventServiceV2.listEvents(params);

      for (const event of response.data) {
        optionsEvents.push({ value: event.id, text: event.name });
      }

      this.optionsEvents = optionsEvents;

      if (this.event) {
        this.selectedEventId = this.event.id;
      }
    } catch (error) {
      this.errorMessage = this.parseError(error).message;
    }

    this.canViewDonations = await unleashFeatureEnabled(UnleashKeys.ViewDonations);
  },
  methods: {
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },

    validate() {
      // Using the pattern from CreateRaffleWizardModal.vue
      const lastName = this.$refs.lastName.isValid();
      const firstName = this.$refs.firstName.isValid();
      const email = this.$refs.email.isValid();
      const postal = this.$refs.postalCode.isValid();

      return firstName && lastName && email && postal;
    },

    validateTicketPackagesOnChange() {
      // Set valid if entire parent raffle ticket block has been filled in
      if (this.ticketPackage && this.quantityValid) {
        this.ticketPackageValid = true;
        this.quantityValid = true;
      }

      // Set valid if entire child raffle ticket block has been filled in
      if (this.ticketPackageAddon && this.quantityAddonValid) {
        this.ticketPackageAddonValid = true;
        this.quantityAddonValid = true;
      }

      // If ticket package from either is selected without quantity
      if (this.ticketPackage && this.quantity === null) {
        this.ticketPackageValid = true;
        this.quantityValid = false;
        this.quantityErrorMessage = 'The quantity field is required if a ticket package is selected';
      }

      if (this.ticketPackageAddon && this.quantityAddon === null) {
        this.ticketPackageAddonValid = true;
        this.quantityAddonValid = false;
        this.quantityErrorMessage = 'The quantity field is required if a ticket package is selected';
      }

      // If either is deselected
      if (this.ticketPackage === null) {
        this.ticketPackageValid = null;
        this.quantity = null;
        this.quantityValid = null;
      }

      if (this.ticketPackageAddon === null) {
        this.ticketPackageAddonValid = null;
        this.quantityAddon = null;
        this.quantityAddonValid = null;
      }
    },

    quantityValidation(field) {
      if (field === 'quantity') {
        if (this.quantity && this.quantity > 0 && this.quantity <= 100) {
          this.quantityValid = true;
        } else {
          this.quantityValid = false;
          this.quantityErrorMessage = 'The quantity field must be between 0 and 100';
        }
      } else if (field === 'quantityAddon') {
        if (this.quantityAddon && this.quantityAddon > 0 && this.quantityAddon <= 100) {
          this.quantityAddonValid = true;
        } else {
          this.quantityAddonValid = false;
          this.quantityErrorMessage = 'The quantity field must be between 0 and 100';
        }
      }
    },

    donationValidation() {
      if (isNaN(this.donation) || this.donation <= 0 || !Number.isInteger(this.donation)) {
        this.donationErrorMessage = 'The amount must be a whole number and greater than 0';
      } else {
        this.donationErrorMessage = null;
      }
    },

    // Final check called on submission to ensure at least one ticket package field is selected
    preSubmitTicketPackageValidation() {
      if (
        (this.ticketPackageValid && this.quantityValid) ||
        (this.ticketPackageAddonValid && this.quantityAddonValid)
      ) {
        return true;
      } else {
        this.ticketPackageValid = false;
        this.ticketPackageAddonValid = false;
      }
    },

    provinceSelection(province) {
      if (province !== null) {
        const foundProv = this.provinceStateOptions.find((prov) => {
          return prov.value === this.province || prov.text === this.province;
        });

        return foundProv ? foundProv.value : null;
      }
    },

    async addonTicketPackages(childEventId) {
      try {
        const event = await EventServiceV2.retrieveEvent(childEventId);

        const packages = [];

        packages.push({
          value: null,
          text: 'Select one',
          disabled: false
        });

        for (const ticketPackage of event.ticketPackages) {
          if (ticketPackage.availableForPurchaseV2 !== false) {
            const sold = ticketPackage.availableForPurchaseV2 === 'soldout' ? '[Sold Out]' : '';

            packages.push({
              value: ticketPackage,
              text: `${ticketPackage.numTickets} for ${formatCurrency(ticketPackage.price)} ${sold}`,
              disabled: sold ? true : false
            });
          }
        }

        this.optionsAddonTicketPackages = packages;
      } catch (error) {
        this.errorMessage = this.parseError(error).message;
      }
    },

    resetForm() {
      this.createOrderFailed = false;
      this.errorMessage = null;
      this.firstName = null;
      this.lastName = null;
      this.email = null;
      this.phone = null;
      this.ticketPackage = null;
      this.ticketPackageAddon = null;
      this.selectedEventMember = null;
      this.address = null;
      this.postalCode = null;
      this.quantity = null;
      this.quantityAddon = null;
      this.city = null;
      this.selectedOrderType = null;
      this.selectedOrderSource = null;
      this.comment = null;
      this.ticketPackageValid = null;
      this.ticketPackageAddonValid = null;
      this.quantityValid = null;
      this.quantityAddonValid = null;
      this.quantityErrorMessage = null;
      this.donation = null;

      this.$nextTick(() => {
        this.$validator.reset();
      });
    },

    handleOk(event) {
      event.preventDefault();
      this.onSubmit();
    },

    createOrder(eventId, cartItems) {
      return {
        eventId: eventId,
        purchaser: {
          firstName: this.firstName,
          lastName: this.lastName,
          secondaryName: this.secondaryName || undefined,
          email: this.email || undefined,
          phone: this.phone?.replace(/\D/g, ''),
          address: this.address || undefined,
          province: this.province || undefined,
          city: this.city || undefined,
          country: this.country,
          postal: this.postalCode?.replace(/\s/g, '') || undefined
        },
        customerId: this.selectedCustomer ? this.selectedCustomer?.value?.id : undefined,
        eventMemberNumber: this.selectedEventMember?.number?.toString() || undefined,
        source: this.selectedOrderSource || undefined,
        paymentType: this.selectedOrderType || undefined,
        comment: this.comment || undefined,
        cartItems: cartItems,
        donationAmountCents: this.donation ? this.donation * 100 : undefined
      };
    },

    async onSubmit() {
      const ticketPackagesValid = this.preSubmitTicketPackageValidation();
      const customValid = this.validate();
      const valid = await this.$validator.validateAll();
      if (!valid || !customValid || !ticketPackagesValid) {
        return;
      }

      try {
        this.submitting = true;

        if (this.quantity) {
          const cartItems = [{ ticketPackageId: this.ticketPackage.id, numPackages: this.quantity }];

          if (this.selectedEvent.childEvents.length > 0 && this.ticketPackageAddon) {
            cartItems.push({ ticketPackageId: this.ticketPackageAddon.id, numPackages: this.quantityAddon });
          }

          const order = this.createOrder(this.selectedEventId, cartItems);

          await PaymentService.createOrder(order);
        }

        this.modalShow = false;
        this.$router.go();
      } catch (error) {
        this.createOrderFailed = error;

        this.errorMessage = this.parseError(error).message;
      }
      this.submitting = false;
    }
  }
};
</script>

<style>
.create-order .multiselect__tags {
  border-radius: 0.5rem;
}

.create-order .form-group {
  position: relative;
  margin-bottom: 0;
}

.create-order .ticket-container {
  display: flex;
  flex-direction: column;
}

.create-order .ticket-container .form-group {
  flex: 1;
  margin-right: 0.5rem;
}

.create-order .ticket-container .form-group:last-child {
  margin-right: 0;
}

.create-order .ticket-container input[type='number'] {
  text-align: right;
}

.create-order .ticket-container .invalid-feedback {
  top: inherit;
  bottom: 0.5rem;
}

@media screen and (min-width: 36em) {
  .create-order .ticket-container {
    flex-direction: row;
  }
}
</style>
