<template>
  <b-form-group label-for="input-customer-search">
    <template v-slot:label>Customer Search</template>

    <multiselect
      v-model="selected"
      :options="options"
      label="text"
      track-by="text"
      class="customer-search"
      @search-change="searchChanged"
      :internal-search="false"
      :loading="loading"
      :allow-empty="true"
      :placeholder="placeholder"
      data-lpignore="true"
    >
      <template #noResult> No customers found matching search. </template>
    </multiselect>
  </b-form-group>
</template>

<script>
import { getAuth } from '@rafflebox-technologies-inc/auth-service-sdk';
import Multiselect from 'vue-multiselect';
import CustomerServiceV2 from '@/lib/customer-service-v2';

export default {
  components: { Multiselect },
  props: {
    defaultOption: null,
    value: null,
    currentCustomerId: null,
    inputName: {
      type: String
    },
    placeholder: {
      type: String,
      default: 'Find a customer'
    },
    organizationId: {
      type: String
    }
  },
  data() {
    return {
      error: null,
      loading: false,
      options: [],
      selected: null
    };
  },
  async mounted() {
    this.search();
    await this.loadCurrent();
  },
  watch: {
    selected: function () {
      this.$emit('input', this.selected);
    }
  },
  methods: {
    async searchChanged(query) {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      this.searchTimeout = setTimeout(() => this.search(query), 300);
    },

    async loadCurrent() {
      if (!this.currentCustomerId) {
        return;
      }
      this.loading = true;

      const currentCustomer = await CustomerServiceV2.retrieveCustomer(this.currentCustomerId);

      this.selected = {
        value: currentCustomer,
        text: currentCustomer.firstName + ' ' + currentCustomer.lastName
      };

      this.loading = false;
    },

    async search(query) {
      this.loading = true;

      const sessionUser = await getAuth().sessionUser();
      const params = {
        pageSize: 100,
        sortBy: 'lastName',
        sortDir: 'asc',
        organizationId: sessionUser.organizationUuid
      };

      if (query) {
        params.search = query;
      }

      try {
        const response = await CustomerServiceV2.listCustomers(params);

        const options = response.data.map((object, index) => {
          return { key: index, value: object, text: object.firstName + ' ' + object.lastName };
        });

        this.options = options;
      } catch (error) {
        this.errorMessage = this.parseError(error).message;
      }
      this.loading = false;
    }
  }
};
</script>

<style scoped>
.is-invalid.customer-search .multiselect__tags {
  border-color: #dc3545;
}
.is-valid.customer-search .multiselect__tags {
  border-color: #28a745;
}
</style>
